<template>
	<div class="news-details">
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="about-main-box">
			<!-- 导航栏 -->
			<div class="crumbs">
				<div class="cru-logo">
					<img src="../../assets/images/home/daohang_logo.png" alt="">
				</div>
				<el-breadcrumb separator=">">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/news' }">新闻中心</el-breadcrumb-item>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="all-introduce">
				<div class="title">{{title}}</div>
				<div class="crate-time">发布时间：{{news_info.create_time}}</div>
				<div class="news-info" v-html="news_info.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import BannerOther from '@/components/banner-other/index.vue'
	import{
		getxwxiangqing
	}from '@/api/joggle.js'
	export default {
		name:'News-details',
		components: {
			BannerOther
		},
		data() {
			return {
				img_url: '',
				chinese_name: '新闻中心',
				english_name: 'NEWS CENTER',
				id:'', //新闻id
				news_info:{},
				title:''
			}
		},
		activated() {
			var that = this
			that.id = that.$route.query.id;
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if(company_info){
				that.img_url = company_info.news_image;
			}
			that.getListDetails();
		},
		mounted() {
			
		},
		methods:{
			// 获取新闻详情
			getListDetails(){
				var that = this
				var obj ={
					id:that.id
				}
				getxwxiangqing(obj).then(res => {
					if (res.code == 1) {
						that.news_info = res.data;
						that.title = that.news_info.title;
						// 修改浏览器显示名称
						document.title = that.title + '-新闻中心-MG影像';
					}else{
						
					}
				})
			}
		},
		// 离开页面事件
		beforeDestroy() {
		},
	}
</script>

<style lang="scss">
	@import '../../assets/css/news-details.scss';
	@import '../../assets/css/public.scss';
</style>
